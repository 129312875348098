import sample1 from './assets/gallery/sample_1.jpg';
import sample2 from './assets/gallery/sample_2.jpg';
import sample3 from './assets/gallery/sample_3.jpg';
import sample4 from './assets/gallery/sample_4.jpg';
import sample5 from './assets/gallery/sample_5.jpg';
import sample6 from './assets/gallery/sample_6.jpg';
import sample7 from './assets/gallery/sample_7.jpg';
import sample8 from './assets/gallery/sample_8.jpg';
import sample9 from './assets/gallery/sample_9.jpg';
import sample10 from './assets/gallery/sample_10.jpg';

export const IMAGES = [
	sample1, 
	sample2, 
	sample3, 
	sample4, 
	sample5, 
	sample6, 
	sample7, 
	sample8, 
	sample9, 
	sample10
];
export const DEFAULT_SEASON = '1';
export const TOURNAMENT_DETAILS_REFRESH_RATE_MILLIS = 5000;
export const API_FUNCTION_URL =
  'https://f5tknvbs2jrkhvcdprxnagffam0xyipd.lambda-url.us-east-1.on.aws';

export const TOURNAMENT_STATE_NOT_STARTED = 'NOT_STARTED';
export const TOURNAMENT_STATE_ROUND_ROBIN_ONE = 'ROUND_ROBIN_ONE';
export const TOURNAMENT_STATE_ROUND_ROBIN_TWO = 'ROUND_ROBIN_TWO';
export const TOURNAMENT_STATE_COMPLETED = 'COMPLETED';
export const TOURNAMENT_STATES = {
	notStarted: TOURNAMENT_STATE_NOT_STARTED,
	roundRobinOne: TOURNAMENT_STATE_ROUND_ROBIN_ONE,
	roundRobinTwo: TOURNAMENT_STATE_ROUND_ROBIN_TWO,
	completed: TOURNAMENT_STATE_COMPLETED,
};
