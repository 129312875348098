import { TOURNAMENT_STATES } from '../constants';

/*
Format for `tournamentDetails`

{
  "standings": [
    {
      "position": 1, // Values 1-8, 1 being the first placed participant
      "name": "Shirin",
      "score": 126
    },
    ...
  ],
  "scheduleAndResults": [
    {
      "roundRobin": 1, // Values 1 or 2, describing the round robin round #, where #1 is the first round of 3 matches and #2 is the next round of 3 matches
      "time": "6:30PM",
      "results": {
        "groupA": {
          "court": 1, // values 1-4, aka JV courts
          "teamA": {
            "participants": [
              "Shirin",
              "Sandra"
            ],
            "points": 21
          },
          "teamB": {
            "participants": [
              "Helena",
              "Jen Chan"
            ],
            "points": 19
          }
        },
        "groupB": {
          "court": 2,
          "teamA": {
            "participants": [
              "Lay Hoon",
              "Kirsten"
            ],
            "points": 21
          },
          "teamB": {
            "participants": [
              "Angeline",
              "Player 8"
            ],
            "points": 1
          }
        }
      }
    },
    ...
  ]
}
*/

export const getTournamentState = (tournamentDetails) => {
	// Not started if no participant has any points
	if ((tournamentDetails.standings || []).every(s => !s.score)) {
		return TOURNAMENT_STATES.notStarted;
	}

	// Round robin one if all round robin 2 matches have scored zero points
	if ((tournamentDetails.scheduleAndResults || []).filter(s => s.roundRobin === 2)
		.every(s => 
			!s.results.groupA.teamA.points 
            && !s.results.groupA.teamB.points 
            && !s.results.groupB.teamA.points 
            && !s.results.groupB.teamB.points
		)) {
		return TOURNAMENT_STATES.roundRobinOne;
	}

	// Round robin two if some round robin 2 matches have no teams with at least 21 points
	if ((tournamentDetails.scheduleAndResults || []).filter(s => s.roundRobin === 2)
		.some(s => 
			((s.results.groupA.teamA.points || 0) < 21 && (s.results.groupA.teamB.points  || 0) < 21)
            || ((s.results.groupB.teamA.points || 0) < 21 && (s.results.groupB.teamB.points  || 0) < 21)
		)) {
		return TOURNAMENT_STATES.roundRobinTwo;
	}

	return TOURNAMENT_STATES.completed;
};

export const formatStandingsLine = (standing) => 
	`#${standing.position}: ${standing.name} - ${standing.score} points`;

export const formatScheduleLine = (scheduleAndResults) =>  {
	//Fix this messy ass shit

	const groupA = scheduleAndResults.results.groupA;
	const groupATeamAParticipants = `${groupA.teamA.participants[0]}/${groupA.teamA.participants[1]}`;
	const groupATeamBParticipants = `${groupA.teamB.participants[0]}/${groupA.teamB.participants[1]}`;
	const groupAScore = `${groupA.teamA.points}:${groupA.teamB.points}`;
	const groupALine = `${scheduleAndResults.time}: ${groupATeamAParticipants} ${groupAScore} ${groupATeamBParticipants} (Court ${groupA.court})`;

	const groupB = scheduleAndResults.results?.groupB;
	const groupBTeamAParticipants = `${groupB.teamA.participants[0]}/${groupB.teamA.participants[1]}`;
	const groupBTeamBParticipants = `${groupB.teamB.participants[0]}/${groupB.teamB.participants[1]}`;
	const groupBScore = `${groupB.teamA.points}:${groupB.teamB.points}`;
	const groupBLine = `${scheduleAndResults.time}: ${groupBTeamAParticipants} ${groupBScore} ${groupBTeamBParticipants} (Court ${groupB.court})`;

	return [groupALine, groupBLine];
};