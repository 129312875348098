import React from 'react';
import './Home.css';
import poster from '../assets/Little-Pickletes-Cup-Badge.png';
import SnowingBackground from '../components/SnowingBackground';
import CustomCountdown from '../components/CustomCountdown';
import { Image, Typography, Space, Collapse } from 'antd';

const { Text } = Typography;

const SEASON_1_START_DATE_TIME =
	new Date(2024, 11, 20).getTime() + 18 * 60 * 60 * 1000; // 18 hours after local time 00:00

const FAQ_QUESTIONS_ANSWERS = [
	{
		key: '1',
		label: 'When is the tournament?',
		children: <p>{'Friday, 20 Dec, 6PM to 10PM'}</p>,
	},
	{
		key: '2',
		label: 'How many people are participating?',
		children: <p>{'Eight participants'}</p>,
	},
	{
		key: '3',
		label: 'How many games do I have to play?',
		children: (
			<p>
				{
					'Everyone will play six games each. We will circulate the groupings and match schedule once all the participants are in, or latest by 23 November.'
				}
			</p>
		),
	},
	{
		key: '4',
		label: 'Will there be breaks in between games?',
		children: (
			<p>{'YES! There will be around 10-15 mins break between each game'}</p>
		),
	},
	{
		key: '5',
		label: 'Will there be warmup time?',
		children: <p>{'Please warm-up before the first game at 6:30PM.'}</p>,
	},
	{
		key: '6',
		label: 'What happens if it rains on that day?',
		children: (
			<p>
				{
					'Unfortunately there are no wet weather plans, and the Little Picklete Cup will have to be rescheduled to one of the first two weeks of January 2025.'
				}
			</p>
		),
	},
	{
		key: '7',
		label: 'Who is my partner(s) for the tournament?',
		children: (
			<p>
				{
					'The eight participants will be split into two groups of four, i.e. ABCD and EFGH. Each participant will play 3 games of round-robin (AB v CD, AC v BD, AD v BC)'
				}
				<br />
				<br />
				{
					'After the first 3 games, there will be a group shuffle to re-balance the groups so the top two of each group will get grouped together. Each participant will then play another 3 games of round-robin.'
				}
			</p>
		),
	},
	{
		key: '8',
		label: 'How are the winner(s) determined?',
		children: (
			<p>
				{
					'Each game is played to 21 (first to 21, no deuce). At the end of the six games, the participants will be sorted according to number of points won.'
				}
			</p>
		),
	},
	{
		key: '9',
		label: 'What happens if there is a tie-break?',
		children: (
			<p>
				{
					'If there is a tie-break, we will compare the point differential based on the opposing matchups against each other. E.g. if A and B are tied, will compare point differentials for AC v BD and AD v BC (and any other opposing match ups).'
				}
			</p>
		),
	},
	{
		key: '10',
		label: 'Can I track the live score / rankings?',
		children: (
			<p>
				{
					'There will be a live leaderboard - check back on the schedule tab when the tournament has started!'
				}
			</p>
		),
	},
	{
		key: '11',
		label: 'What\'s next?',
		children: (
			<p>
				{
					'Stay tuned! We will update this page with player profiles, the full tournament schedule, and more.'
				}
			</p>
		),
	},
];

const Home = () => {
	return (
		<section className="body-wrapper">
			<div id="home-container" className="container">
				<SnowingBackground />
				<div className="hero-section section">
					<Space
						direction="vertical"
						size="middle"
						style={{
							display: 'flex',
						}}
					>
						<Image src={poster} placeholder={false} preview={false} />
						<CustomCountdown targetDate={SEASON_1_START_DATE_TIME} />
					</Space>
				</div>
				<div className="faq-section section">
					<Space
						direction="vertical"
						size="large"
						style={{
							display: 'flex',
						}}
					>
						<Typography.Title
							editable={false}
							level={2}
							style={{
								margin: 0,
							}}
						>
							FAQs
						</Typography.Title>
						<Collapse
							items={FAQ_QUESTIONS_ANSWERS}
							defaultActiveKey={[]}
							bordered={false}
							size="large"
						/>
					</Space>
				</div>
				<div className="copyright-wrapper">
					<Text id="copyright">© Bobos.Co 2024</Text>
				</div>
			</div>
		</section>
	);
};

Home.propTypes = {
};

export default Home;
