import React from 'react';
import './Gallery.css';
import { IMAGES } from '../constants';
import { Image, Typography } from 'antd';
import Masonry from 'react-responsive-masonry';

const { Text } = Typography;

const Gallery = () => {
	return (
		<section className="body-wrapper gallery-body-wrapper">
			<div id="gallery-container">
				<Masonry
					columnsCount={3}
					gutter={16}
					containerTag="masonry-wrapper"
					itemTag="masonry-item"
				>
					{IMAGES.map((img, idx) => (
						<Image key={idx} src={img} />
					))}
				</Masonry>
			</div>
			<div className="copyright-wrapper">
				<Text id="copyright">© Bobos.Co 2024</Text>
			</div>
		</section>
	);
};

Gallery.propTypes = {
};

export default React.memo(Gallery);
