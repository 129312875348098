import React, { useEffect, useState } from 'react';
import './Main.css';
import Home from '../pages/Home';
import Schedule from '../pages/Schedule';
import Gallery from '../pages/Gallery';
import { Tabs } from 'antd';
import StickyBox from 'react-sticky-box';
import { getTournamentDetails } from '../lambda';
import {DEFAULT_SEASON,
	TOURNAMENT_DETAILS_REFRESH_RATE_MILLIS,} from '../constants';

const renderTabBar = (props, DefaultTabBar) => (
	<StickyBox
		offsetTop={16}
		offsetBottom={20}
		style={{
			zIndex: 1,
		}}
	>
		<DefaultTabBar
			{...props}
			style={{
				borderRadius: 8,
				paddingLeft: 12,
				paddingRight: 12,
			}}
		/>
	</StickyBox>
);

const Main = () => {
	const [standings, setStandings] = useState([]);
	const [scheduleAndResults, setScheduleAndResults] = useState([]);

	const queryParams = new URLSearchParams(window.location.search);
	const renderSchedule = queryParams.get('show_schedule') === 'true';

	useEffect(() => {
		const fetchTournamentDetails = () => {
			getTournamentDetails(DEFAULT_SEASON).then((res) => {
				setStandings(res.standings);
				setScheduleAndResults(res.scheduleAndResults);
			});
		};

		fetchTournamentDetails();
		setInterval(() => {
			fetchTournamentDetails();
		}, TOURNAMENT_DETAILS_REFRESH_RATE_MILLIS);
	}, []);

	return (
		<>
			<Tabs
				id={'navigation-bar'}
				defaultActiveKey="home"
				renderTabBar={renderTabBar}
				items={[
					{
						key: 'home',
						label: 'Home',
						children: <Home />,
						forceRender: true,
					},
					renderSchedule
						? {
							key: 'schedule',
							label: 'Schedule',
							children: (
								<Schedule
									tournamentDetails={{
										standings: standings,
										scheduleAndResults: scheduleAndResults,
									}}
								/>
							),
							forceRender: true,
						}
						: {
						},
					{
						key: 'gallery',
						label: 'Gallery',
						children: <Gallery />,
						forceRender: true,
					},
				]}
			/>
		</>
	);
};

Main.propTypes = {
};

export default React.memo(Main);
