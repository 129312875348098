import React from 'react';
import './Schedule.css'; 
import PropTypes from 'prop-types';
import TournamentDetails from '../components/TournamentDetails';

const Schedule = ({ tournamentDetails }) => {
	return (
		<section className ='body-wrapper'>
			<div id="schedule-container">
				<TournamentDetails tournamentDetails={tournamentDetails} />
			</div>
		</section>
	);
};

Schedule.propTypes = {
	tournamentDetails: PropTypes.object
};

export default React.memo(Schedule);
