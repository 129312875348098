import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spin, List } from 'antd';
import { getTournamentState, formatStandingsLine, formatScheduleLine } from '../helpers/tournament';

const TournamentDetails = ({ tournamentDetails }) => {
	const [isLoading, setIsLoading] = useState(true);
	const { standings, scheduleAndResults } = tournamentDetails;

	useEffect(() => {
		if (standings && scheduleAndResults) {
			setIsLoading(false);
		}
	}, [tournamentDetails]);

	return (
		<div>
			<h1>{`Tournament State: ${  getTournamentState(tournamentDetails)}`}</h1>
			<div
				style={{
					marginTop: '40px',
					marginBottom: '40px',
				}}
			>
				{isLoading ? (
					<div className='w-100 text-center'>
						<Spin />
					</div>
				) : (
					<>
						<List
							header={<div>Standings</div>}
							bordered
							dataSource={
								standings
									.filter(s => s.position <= 3)
									.map(formatStandingsLine)
							}
							renderItem={(item) => <List.Item>{item}</List.Item>}
						/>
						<List
							header={<div>Schedule</div>}
							bordered
							dataSource={scheduleAndResults.flatMap(formatScheduleLine)}
							renderItem={(item) => <List.Item>{item}</List.Item>}
						/>
					</>
				)}
			</div>
		</div>
	);
};

TournamentDetails.propTypes = {
	tournamentDetails: PropTypes.object
};

export default React.memo(TournamentDetails);