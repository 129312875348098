import { API_FUNCTION_URL } from './constants';

export const getTournamentDetails = async (season) =>
	fetch(`${API_FUNCTION_URL}/?season=${season}`)
		.then((response) => response.json())
		.catch(() => {
			return {
				standings: [],
				scheduleAndResults: [],
			};
		});
