import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Statistic } from 'antd';

const CustomCountdown = ({ targetDate }) => {
	const [timeLeft, setTimeLeft] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0
	});
	const [timer, setTimer] = useState(0);

	useEffect(() => {
		const calculateAndSetTimeLeft = () => {
			const now = new Date().getTime();
			const deadline = new Date(targetDate).getTime();
			const difference = deadline - now;

			if (difference > 0) {
				setTimeLeft({
					days: Math.floor(difference / (1000 * 60 * 60 * 24)),
					hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
					minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
					seconds: Math.floor((difference % (1000 * 60)) / 1000)
				});
			} else {
				clearInterval(timer);
			}
		};

		calculateAndSetTimeLeft();
		setTimer(setInterval(() => {
			calculateAndSetTimeLeft();
		}, 1000));
	}, [targetDate]);

	return (
		<div  id="countdown-wrapper">
			<div>
				<Statistic 
					value={timeLeft.days} 
					suffix="Day"
				/>
			</div>
			<div>
				<Statistic 
					value={String(timeLeft.hours).padStart(2, '0')} 
					suffix="Hrs"
				/>
			</div>
			<div>
				<Statistic 
					value={String(timeLeft.minutes).padStart(2, '0')} 
					suffix="Min"
				/>
			</div>
			<div>
				<Statistic 
					value={String(timeLeft.seconds).padStart(2, '0')} 
					suffix="Sec"
				/>
			</div>
		</div>
	);
};

CustomCountdown.propTypes = {
	targetDate: PropTypes.number
};

export default CustomCountdown;